import { NavLink, Link } from 'react-router-dom';
import './Navbar.css';
import { useState } from 'react';
import { navMenu } from '../../data/data';

function Navbar () {
    
    const [isShowingDropdown, setIsShowingDropdown] = useState(false);
    const handleBackdropClick = (e) => {
        console.log(e)
        if (e === 'dropdown--wrapper') {
            setIsShowingDropdown(false);
        }
    }

    return (
        <div className='navbar'>
            <div className='navbar--content'>
                <Link to='/'>
                    <img className='logo' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/unnamed.png?v=1708113018' />
                </Link>
                
                
                <nav className='menu--wrapper'>
                    <menu className='navbar--menu'>
                        {
                            navMenu.map((i) => {
                                return <li><NavLink className={({ isActive }) => isActive ? "active-navbar" : ""} to={i.to}>{i.item}</NavLink></li>
                            })
                        }
                    </menu>
                    <div onClick={() => setIsShowingDropdown(!isShowingDropdown)} className='menu-btn--wrapper'>
                    <button className='menu-btn'>
                        {
                            isShowingDropdown
                            
                            ? <svg width="64px" height="64px" viewBox="0 0 24 24" fill="rgb(237, 240, 242)" xmlns="http://www.w3.org/2000/svg">
                            <g id="Menu / Close_MD">
                            <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="rgb(237, 240, 242)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                            : <svg width="64px" height="64px" viewBox="0 0 24 24" fill="rgb(237, 240, 242)" xmlns="http://www.w3.org/2000/svg">
                            <g id="Menu / Menu_Alt_05">
                            <path id="Vector" d="M5 17H13M5 12H19M11 7H19" stroke="rgb(237, 240, 242)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                        }
                
                        </button>

                </div>
                </nav>
                
                {
                    isShowingDropdown 
                    ? <div onClick={(e) => handleBackdropClick(e.target.className)} className='dropdown--wrapper'><nav  className='navbar--dropdown'>
                        <menu>
                            {
                                navMenu.map((i) => {
                                    return <li><NavLink className={({ isActive }) => isActive ? "active-navbar" : ""} onClick={() => setIsShowingDropdown(false)} to={i.to}>{i.item}</NavLink></li>
                                })
                            }
                        </menu>
                    </nav></div>
                    : <div onClick={(e) => handleBackdropClick(e.target.className)} className='dropdown--wrapper hidden-top'><nav  className='navbar--dropdown '>
                    <menu>
                        {
                            navMenu.map((i) => {
                                return <li><NavLink className={({ isActive }) => isActive ? "active-navbar" : ""} onClick={() => setIsShowingDropdown(false)} to={i.to}>{i.item}</NavLink></li>
                            })
                        }
                    </menu>
                </nav></div>
                }        

        
                
                    
            </div>
        </div>
    );
}

export default Navbar;