import { useState } from 'react';
import './ServiceBullets.css';

function ServiceBullets ({heading, text}) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    }

    if (isExpanded) return (
        <li className='service-bullet-item' onClick={toggleExpanded}>
            <div className='service-bullet-item'>
                <div style={{display: 'flex',  gap: '.25rem'}} >
                    <img className='bullet--arrow rotated90' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/1224679-200.png?v=1708655430' />
                    <p>{heading}</p>

                </div>
            </div>
            <p className='services--bullet'>{text}</p>
        </li>
    );

    return (
        <li className='service-bullet-item' onClick={toggleExpanded}>
            <div className='service-bullet-item'>
                <div style={{display: 'flex',  gap: '.25rem'}} >
                    <img className='bullet--arrow' src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/1224679-200.png?v=1708655430' />
                    <p>{heading}</p>

                </div>
            </div>
            <p className='services--bullet collapsed'>{text}</p>
        </li>
    );
}

export default ServiceBullets;