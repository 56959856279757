import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCIgkOanBOGBo4V7JeX7jY1juAs8rJgkKo",
  authDomain: "aiprotechs.firebaseapp.com",
  projectId: "aiprotechs",
  storageBucket: "aiprotechs.appspot.com",
  messagingSenderId: "884430534893",
  appId: "1:884430534893:web:80412c794e6d9b0eba34e4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage();
const db = getFirestore(app);


export { app, auth, db, storage };