import { useNavigate } from 'react-router-dom';
import './Hero.css';
import { useState } from 'react';
import { doc, setDoc } from "firebase/firestore"; 
import { db } from '../../firebase/firebase';


function Hero () {
    const navigate = useNavigate();
    
    const [email, setEmail] = useState('');

    const changeHandler = (e) => {
        setEmail(e.target.value);
    }

    const submitHandler = async (e) => {
        const capturedLocation = window.location.href;
        const capturedTime = new Date();

        e.preventDefault();
        await setDoc(doc(db, "leadCapture", email), {
            capturedLocation: capturedLocation,
            capturedTime: capturedTime
        });
        navigate('/contact?email=' + email);
    }

    return (
        <div className='hero'>
            <div className='hero--message'>
                <h2>Future proof your <span>business</span></h2>
                <p>Leading IT Solution Nationwide</p>
            </div>
            <div className='hero--tags'>
                <li><img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Shield_Vector_Illustration_Design_4.png?v=1708117636' />Innovate</li>
                <li><img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Shield_Vector_Illustration_Design_5.png?v=1708117636' />Integrate</li>
                <li><img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/web-by-steven-tran_4.png?v=1708995969' />Accelerate</li>
            </div>
            <div className='hero--optin'>
                <form className='hero--form' onSubmit={submitHandler}>
                    <input placeholder='Email' required type='email' onChange={changeHandler}  />       
                    <button>Get Started</button>
                </form>
            </div>
        </div>
    );
}

export default Hero;