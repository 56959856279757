import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import HomePage from './components/HomePage/HomePage';
import SignUp from './components/SignUp/SignUp';
import { useEffect, useState } from 'react';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Overview from './components/Overview/Overview';
import RequestService from './components/RequestService/RequestService';
import Billing from './components/Billing/Billing';
import ClientSupport from './components/ClientSupport/ClientSupport';
import ContactUs from './components/ContactUs/ContactUs';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Careers from './components/Careers/Careers';
import ApplyJob from './components/ApplyJob/ApplyJob';
import NotFound from './components/NotFound/NotFound';

function App() {
  
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userUid, setUserUid] = useState('');

  useEffect(() => {
    const localUid = localStorage.getItem('localUid');
    if (localUid) {
      setUserUid(localUid);
      setIsSignedIn(true);
    }
  }, []);


  return (
    <div>
       <Router>
        <Routes path='/' >
          <Route index element={<HomePage />} />
          <Route path='contact' element={<ContactUs />} />
          <Route path='careers' element={<Careers />} />
          <Route path='apply' element={<ApplyJob />} />
          {/* <Route path='signup' element={<SignUp setIsSignedIn={setIsSignedIn} />} />
          <Route path='login' element={<Login isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />} />
          <Route path='dashboard' element={<Dashboard isSignedIn={isSignedIn} setIsSignedIn={setIsSignedIn} />}>
            <Route index element={<Overview />} /> 
            <Route path='request-service' element={<RequestService />} /> 
            <Route path='billing' element={<Billing />} /> 
            <Route path='support' element={<ClientSupport />} /> 
          </Route> */}
          <Route path='*' element={<NotFound />} />
        </Routes>
        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;
