import Navbar from '../Navbar/Navbar';
import './ContactUs.css';
import { useEffect, useState } from 'react';
import { doc, setDoc } from "firebase/firestore"; 
import { db } from '../../firebase/firebase';
import FooterContact from '../FooterContact/FooterContact';
import FooterNavbar from '../FooterNavbar/FooterNavbar';
import { Helmet } from 'react-helmet';
import FooterAd from '../FooterAd/FooterAd';

function ContactUs () {
    const queryString = window.location.search;
    const [email, setEmail] = useState(queryString.split('=')[1] || '');
    const [customerName, setCustomerName] = useState(null);
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState(null);
    const [isSubmited, setIsSubmited] = useState(false);

    const submitHandler = async (e) => {
        e.preventDefault();
        const contactedTime = new Date();
        const content = {
            name: customerName,
            phone: phone,
            message: message,
            email: email
        };
        

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
        "access_key": "b07a794d-3ca1-4523-8aaf-885d3dd9055c",
        "subject" : "[AI ProTechs] New message received",
        ...content
        });

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };

        fetch("https://api.web3forms.com/submit", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));

        setIsSubmited(true);
    }

    return (
        <>
            <Helmet>
                <title>Contact | AI ProTechs - Future Proof Your Business</title>
            </Helmet>
            <main className='contact'>
                <Navbar />
                <div className='contact--grid'>
                    <div className='contact--left'>
                        <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/web-by-steven-tran_3.png?v=1708976652' />
                    </div>
                    <div className='contact--right'>
                            <div className='heading--container'>
                                <h2>Contact Us</h2>
                                <p>Ready to Collaborate or Have Questions?</p>
                                <p>
                                    Let's fuel your tech goals! Fill out the form below and we'll be in touch fast to assist you. Don't miss out on unlocking your digital potential!
                                </p>
                            </div>
                        <div style={{padding: '.5rem'}}>
                        <div className='form--container' >
                            {
                                isSubmited 
                                ? <p className='success'>✔️  Thank you for reaching out to us! Your message has been received and a member of our team will be in touch with you shortly. We appreciate your patience and look forward to connecting with you soon!</p>
                                : <form onSubmit={submitHandler}>
                                    <label>
                                        <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech.png?v=1709143604' />
                                        <input required type='email' placeholder='Email*' onChange={(e) =>setEmail(e.target.value)} value={email} id="email" />
                                    </label>
                                    <label>
                                        <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech_1.png?v=1709144279' />
                                        <input  typle='number' placeholder='Phone'  onChange={(e) =>setPhone(e.target.value)} />
                                    </label>
                                    <label>
                                        <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech_2.png?v=1709144347' />
                                        <input  type='text' placeholder='Name' onChange={(e) =>setCustomerName(e.target.value)}/>
                                    </label>
                                    <label>
                                        <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech_3.png?v=1709144439' />
                                        <textarea required placeholder='Message*' onChange={(e) =>setMessage(e.target.value)}  />
                                    </label>
                                    <button>Submit</button></form>
                            }
                        </div>
                        </div>
                    </div>

                </div>
                <FooterContact />
                <FooterNavbar />
            </main>
            <FooterAd />
        </>
    );
}

export default ContactUs;