import { useState } from 'react';
import './ServiceCards.css';

function ServiceCards ({img, serviceName, text}) {
    const [isFlipped, setIsFlipped] = useState(false);

    const toggleFlip = () => {
        setIsFlipped(!isFlipped);
    }

    if (isFlipped) return  <article className=' services--item' onClick={toggleFlip} ><img className='services--card flipped' src={img} /><p>{text}</p></article>

    return (
        <article className=' services--item' onClick={toggleFlip} ><img className='services--card services--item' src={img} /><h4>{serviceName}</h4></article>
    );
}

export default ServiceCards;