import './HomePage.css';
import Helmet from 'react-helmet';
import Hero from '../Hero/Hero';
import Navbar from '../Navbar/Navbar';
import ServicesSection from '../ServicesSection/ServicesSection';
import ServicesSectionB from '../ServicesSection-b/ServicesSection-b';
import WhyUs from '../WhyUS/WhyUs';
import LeadFormBottom from '../LeadFormBottom/LeadFormBottom';
import FooterContact from '../FooterContact/FooterContact';
import FooterNavbar from '../FooterNavbar/FooterNavbar';
import FooterAd from '../FooterAd/FooterAd';
import HeaderAd from '../HeaderAd/HeaderAd'

function HomePage () {
    return (
        <>
            <Helmet>
            <title>AI ProTechs - Future Proof Your Business</title>
            </Helmet>
            <main className='home'>
                <Navbar />
                <Hero />
                <HeaderAd />
                <ServicesSection />
                <ServicesSectionB />
                <WhyUs />
                <LeadFormBottom />
                <FooterContact />
                <FooterNavbar />
            </main>
            <FooterAd />
        </>
    );
}

export default HomePage;