import FooterContact from '../FooterContact/FooterContact';
import FooterNavbar from '../FooterNavbar/FooterNavbar';
import Navbar from '../Navbar/Navbar';
import './ApplyJob.css';
import jobListing from '../../data/jobListing';
import { useForm } from "react-hook-form"
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../firebase/firebase';
import { Helmet } from 'react-helmet';
import NotFound from '../NotFound/NotFound';


function ApplyJob () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const job = urlParams.get('job');
    const currentJob = jobListing.find((i) => i.slug == job);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [resumeLink, setResumeLink] = useState('');
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    
    const onFileUpload = (e) => {
        const selected = e.target.files[0];
        if (!selected) return;
        setFile(selected);
    }

    const onSubmit = (data) => {
       
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
    "access_key": "bb4115bf-5c1f-4a28-9551-a6e39827defb",
    "subject": "[AI ProTechs] New job application received",
    "applied position": currentJob.title,
    "Applicant's name": data['full-name'],
    "Email": data['email'],
    "Phone": data['phone'],
    "Current location": data['current-location'],
    "Cover letter/Additional information": data['additional-information'],
    "Resume" : resumeLink,
    });

    const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
    };

    fetch("https://api.web3forms.com/submit", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
    
    setIsSubmitted(true);
    }

    useEffect(() => {
        if (!file) return;

        const filePath = `resume/${file.name}`;
        const resumeRef = ref(storage, filePath);

        setIsUploading(true);
        uploadBytes(resumeRef, file).then((snap) => {
            getDownloadURL(resumeRef, filePath).then((url) => {
                setResumeLink(url);
            })
            setIsUploading(false);
            setFile(null);
        });

    }, [file])

    if (!currentJob) {
        return (
   
            <NotFound />
            
        )
    }
    
    return (
        <div>
            <Helmet>
                <title>Apply | AI ProTechs - Future Proof Your Business</title>
            </Helmet>
            <main className='page'>
                <Navbar />
                <div className='page--content apply-job'>
                <div className='page--hero apply-job--bg'>
                        <h2>Apply Now</h2>
                    </div>
                    <div className='content--wrapper'>
                        <h3>{currentJob.title}</h3>
                        <div className='job-description' dangerouslySetInnerHTML={{__html: currentJob.longDescription}}></div>
                        {
                            isSubmitted
                            ? <div>
                                <p className='success applied'>✔️ Thank you for submitting your application, we will review and get back to you shortly!</p>
                                <Link className='more-jobs' to='/careers' >➡ See other opportunities</Link>
                            </div>
                            : <form onSubmit={handleSubmit(onSubmit)} className='apply--form' >
                                <label>Resume/CV
                                </label>
                                <input onChange={onFileUpload} type='file' />
                                <label>Full Name *
                                    <input {...register("full-name")} required type='text' />
                                </label>
                                <label>Email *
                                    <input {...register("email")} required type='email' />
                                </label>
                                <label>Phone *
                                    <input {...register("phone")} required type='number' />
                                </label>
                                <label>Current location *
                                    <input {...register("current-location")} required type='text' />
                                </label>

                                <label>Cover letter/Additional information 
                                    <textarea {...register("additional-information")} type='text' />
                                </label>

                                <button className='btn--main'>Submit application</button>
                            </form>
                        }
                    </div>
                </div>

                <FooterContact />
                <FooterNavbar />
            </main>
        </div>
    );
}

export default ApplyJob;