import { Link } from 'react-router-dom';
import FooterContact from '../FooterContact/FooterContact';
import FooterNavbar from '../FooterNavbar/FooterNavbar';
import Navbar from '../Navbar/Navbar';
import './NotFound.css';

function NotFound () {
    return (
        <div className='page not-found'>
            <Navbar />
            <div className='page--content'>
                <div className='content--wrapper'>
                    <img className='not-found--img' src='https://t4.ftcdn.net/jpg/03/88/63/83/360_F_388638369_wSBADhKfhiTx6Q5Pz1xfdpy6zotku1Sg.jpg' />
                    <h2>Sorry this page does not exist</h2>
                    <Link style={{textDecoration: 'none'}} className='btn--main' to='/' >Return to Home page</Link>
                </div>
            </div>
            <FooterContact />
            <FooterNavbar />
        </div>
    )
}

export default NotFound;