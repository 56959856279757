import './Careers.css';
import FooterAd from '../FooterAd/FooterAd';
import FooterContact from '../FooterContact/FooterContact';
import FooterNavbar from '../FooterNavbar/FooterNavbar';
import JobListing from '../JobListing/JobListing';
import Navbar from '../Navbar/Navbar';

import { Helmet } from 'react-helmet';
import HeaderAd from '../HeaderAd/HeaderAd';


function Careers () {
    const whyUs = [
        {
          "title": "Innovative Culture",
          "text": "At AI ProTechs, we believe that innovation is the key to success. We encourage our employees to think outside the box and bring new ideas to the table.",
          "img": "https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech_7.png?v=1711583339"
        },
        {
          "title": "Diverse Opportunities",
          "text": "As an IT service provider with a wide range of offerings, we have opportunities for professionals across various domains, from network specialists to security experts.",
          "img": "https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech_6.png?v=1711583340"
        },
        {
          "title": "Continuous Learning",
          "text": "The tech landscape is always changing, and so are we. We invest in continuous education and training programs to ensure our team stays ahead of the curve.",
          "img": "https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech_8.png?v=1711583340"
        },
        {
          "title": "Nationwide Reach",
          "text": "With a commitment to providing services nationwide, our team members in various settings and locations have the opportunity to work with us.",
          "img": "https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech_9.png?v=1711583339"
        }
      ];
      
    return (
        <div>
            <Helmet>
                <title>Careers | AI ProTechs - Future Proof Your Business</title>
            </Helmet>
        <main>
            
            <Navbar />
            

            <div className='page careers'>
                <div className='page--hero careers--bg'>
                    <h2>Take <strong>Control</strong> of Your Career with AI ProTechs</h2>
                </div>
                <div className='page--content'>
                    <div className='content--wrapper' >
                        <HeaderAd />
                        <div style={{textAlign: 'center'}}>
                            <p>At <strong>AI ProTechs</strong>, we're more than just a technology company—we're a team of problem solvers, innovators, and thinkers who are passionate about empowering businesses with cutting-edge IT solutions. As we grow, we're looking for talented individuals who share our dedication to excellence, innovation, and customer satisfaction.</p>
                            <div style={{marginTop: '4rem'}}>
                                <a href='#apply' className='btn--main'>Apply now</a>
                            </div>
                        </div>
                        <div className='careers--why'>
                            <h3 style={{textAlign: 'center'}}>Why work for us</h3>
                            <ul className='cards'>
                                {
                                    whyUs.map(({title, text, img}) => {
                                        return <li>
                                            <div className='card--wrapper'>
                                                <img src={img} />
                                                <div>
                                                    <h4>{title}</h4>
                                                    <p className='card--text'>{text}</p>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                
                                }
                            </ul>
                        </div>
                        <div style={{scrollBehavior: 'smooth'}} id="apply"> 
                            <h3>Ready to Make an Impact?</h3>
                            <p>If you're ready to take on new challenges and make a tangible impact on the future of businesses nationwide, apply for a position below.</p>
                        </div>
                        <JobListing />
                    </div>
                </div>
                    
               

            </div>
            <FooterContact />
            <FooterNavbar />
        </main>
        <FooterAd />
        </div>
    )
}

export default Careers;