import './JobListing.css';
import jobListing from '../../data/jobListing';
import { Link } from 'react-router-dom';

function JobListing () {


    return (
       <div>
            <ul className='jobs'>
                {
                    jobListing.map(({title,slug, shortDescription, tags}) => {
                        return (
                            <li className='job'>
                                <div style={{maxWidth: '800px'}}>
                                    <h4>
                                        {title}
                                    </h4>
                                    <p className='job--description'>{shortDescription}</p>
                                    <ul className='job--tags'>
                                        {
                                            tags.map((tag) => {
                                                return <li className='job--tag'>
                                                    {tag}
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                               <Link to={'/apply?job=' + slug } style={{margin: 'auto 1rem auto auto'}}>Apply now ➜</Link>
                            </li>
                        )
                    })
                }
            </ul>
       </div>
    );
}

export default JobListing;