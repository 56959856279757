const JobListing = [
    {
        title: 'Part-time W2 IT Field Engineer',
        slug: 'it-field-engineer',
        tags : ['Part-time', 'W2'],
        longDescription: `<p data-mce-fragment="1"><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">At AI ProTechs </span><a href="http://www.aiprotechs.co" data-mce-fragment="1" data-mce-href="http://www.aiprotechs.co"><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">www.aiprotechs.co</span></a><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;"> you will perform for our clients all the services we offer. We are an IT service company. We want all our IT professionals to be trained at all levels as our focus is on (1) Mobile Devices, (2) Networking, (3) Hardware, (4) Virtualization and Cloud Computing, (5) Hardware and Network Troubleshooting, (6) Operating Systems, (7) Security, (8) Software Troubleshooting, and (9) Operational Procedures.</span><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;"><br data-mce-fragment="1"></span><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;"><br data-mce-fragment="1"></span><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">Our services include voice over IP (VOIP), telecom, security, CCTV, cameras, data/networking, access points, point of sale (POS), IOT, POTS installation, structured cabling, wifi heat mapping, printer, copier, server, audiovisual, access controls, roll-outs, fiber installation, and structured cabling. These are the kinds of jobs you will be engaged in but working mainly on computing devices.&nbsp;</span></p>
        <p data-mce-fragment="1"><b data-mce-fragment="1">Duties:</b></p>
        <p data-mce-fragment="1"><span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">The FE will be subject to a background check and a drug test. This is a W2 part-time position and will be responsible for handling on-site installation, servicing, troubleshooting, and repairs for voice over IP (VOIP), telecom, security, CCTV, cameras, data/networking, access points, point of sale (POS), IOT, POTS installation, structured cabling, wifi heat mapping, printer, copier, server, audiovisual, access controls, roll-outs, fiber installation, and structured cabling jobs. Field Engineers may work on-site at factories, retail stores, manufacturing plants, hospitals, or on construction sites.</span></p>`,
        shortDescription: 'Perform on-site installation, service, and repair tasks'
    },
    
];

export default JobListing;