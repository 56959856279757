import './WhyUs.css';

function WhyUs() {
    const whyUs = [
        {img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_44.png?v=1708376428', heading: "Expertise Across ​Diverse Domains", text: "Our team comprises IT ​professionals trained at all levels ​and specializes in a wide array ​of fields, including mobile ​devices, networking, hardware, ​security, and more."},
        {img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_45.png?v=1708376427', heading: "Cutting-Edge ​Technology ​Solutions", text: "Stay ahead of the curve with our ​cutting-edge technology ​solutions. We leverage the latest ​advancements in technology to ​optimize your infrastructure and ​enhance efficiency."},
        {img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_46.png?v=1708376427', heading: "Commitment to ​Excellence", text: "At AI ProTechs, excellence is not ​just a goal; it's our standard. We ​are committed to delivering the ​highest quality of service, ​professionalism, and reliability in ​every project we undertake. Your ​satisfaction is our top priority."},
        {img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_47.png?v=1708376427', heading: "Nationwide Service ​Coverage", text: "No matter where your business is ​located, our nationwide network ​of experienced technicians and ​travel teams ensures that you ​receive prompt and reliable ​service whenever and wherever ​you need it."},
        {img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_48.png?v=1708376427', heading: "Transparent ​Communication ​and Pricing", text: "Transparency is key to building ​trust. We believe in open ​communication and upfront ​pricing, so you know exactly ​what to expect every step of the ​way. No hidden fees, no ​surprises—just honest, reliable ​service."},
        {img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_49.png?v=1708376427', heading: "Responsive ​Customer Support", text: "Have a question or need ​assistance? Our dedicated ​customer support team is here ​to help. Whether it's ​troubleshooting technical issues ​or providing guidance on IT ​strategy, we're just a phone call ​or email away, ready to assist ​you whenever you need us."}
    ];
    return (
        <div className='why-us'>
            <div className='why-us--container'>
                <div className='why-us--grid'>
                    <div className='why-us--left'>
                        <h3>Why Choose AI ProTechs</h3>
                        <div className='why-us--underline'></div>
                    </div>

                    <div className='why-us--right'>
                        <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_43.png?v=1708376431' />
                        <p>At AI ProTechs, we understand that selecting the right IT ​service provider is crucial for the success and security of ​your business.</p> 
                        <p>Here are several reasons why choosing us sets you on the ​path to seamless technological advancement:</p>
                    </div>
                </div>

                <div className='why-us--bottom'>
                    {
                        whyUs.map((i) => {
                            return (
                                <article>
                                    <img src={i.img} />
                                    <h4>{i.heading}</h4>
                                    <p>{i.text}</p>
                                </article>
                            );
                        })
                    }
                </div>

            </div>

        </div>
    );
}

export default WhyUs;