import { Link, NavLink } from 'react-router-dom';
import './FooterNavbar.css';
import { navMenu } from '../../data/data';

function FooterNavbar () {
    return (
        <div className='footer-nav'>
            <Link to='/' className='bottom--logo' >
            <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/unnamed.png?v=1708113018' />
            </Link>
            <nav>
                <menu>
                    {
                        navMenu.map(({item, to}) => {
                            return (
                                <li><NavLink className={({ isActive }) => isActive ? "active-navbar": ''} to={to}>{item}</NavLink></li>
                            )

                        })
                    }
                </menu>
            </nav>
        </div>
    )
}

export default FooterNavbar;