import ServiceBullets from '../ServiceBullets/ServiceBullets';
import './ServicesSection-b.css';

function ServicesSectionB () {
    const servicesBullet = [
        'Voice over IP (VOIP)', 'Telecom', 'Security', 'CCTV', 'Cameras', 'Data/Networking', 'Access Points', 'Point of Sale (POS)', "IoT", "POTS Installation", 'Structured Cabling', 'WiFi Heat Mapping', 'Printer', 'Copier', 'Server', 'Audiovisual', 'Access Controls', 'Roll-outs', 'Fiber Installation', 'Structured Cabling'
    ];

    const serviceBullets = [
        {heading:  'Voice over IP (VOIP)', text: "Transform your communication infrastructure with our VoIP solutions. We design, deploy, and manage VoIP systems that offer reliable, cost-effective, and feature-rich communication capabilities for your organization." },
        {heading:  'Telecom', text: "Stay connected with our telecom services. Whether you need assistance with traditional phone systems or VoIP integration, we provide end-to-end telecom solutions tailored to your specific requirements." },
        {heading: 'Security Solutions', text: "Protect your digital assets and safeguard your business with our advanced security solutions. From cybersecurity assessments and threat detection to security training and compliance management, we help you stay ahead of evolving threats."},
        {heading: 'CCTV and Camera', text: "Monitor and secure your premises with our CCTV and camera systems. We design and install high-definition surveillance solutions that provide real-time monitoring, recording, and remote access for enhanced security and peace of mind."},
        {heading: 'Data/Networking', text: "Build a robust and scalable network infrastructure with our data and networking services. From network design and implementation to optimization and troubleshooting, we ensure seamless connectivity and communication for your business."},
        {heading: 'Access Points', text: "Extend the reach of your wireless network with our access point installation services. Our certified technicians deploy access points strategically to ensure optimal coverage and performance throughout your premises."},
        {heading: 'Point of Sale (POS)', text: "Streamline your retail operations with our POS systems. We offer comprehensive POS solutions that facilitate transactions, inventory management, and customer engagement, helping you improve efficiency and customer satisfaction."},
        {heading: 'IoT Integration', text: "Embrace the power of IoT with our integration services. We connect IoT devices and sensors to your network, enabling data collection, analysis, and automation for improved operational efficiency and decision-making."},
        {heading: 'POTS Installation', text: "Ensure reliable voice communication with our POTS (Plain Old Telephone Service) installation services. We set up and configure traditional phone lines to support your business communication needs."},
        {heading: 'Structured Cabling', text: "Establish a solid foundation for your network infrastructure with our structured cabling solutions. Our certified technicians design and install structured cabling systems that support high-speed data transmission and scalability."},
        {heading: 'WiFi Heat Mapping', text: "Optimize your wireless network performance with our WiFi heat mapping services. We conduct comprehensive site surveys and analysis to identify coverage gaps, interference sources, and performance bottlenecks, allowing for strategic placement of access points."},
        {heading: 'Printer and Copier', text: "Enhance document management and workflow efficiency with our printer and copier services. From installation and configuration to maintenance and repair, we ensure your printing devices operate smoothly and reliably."},
        {heading: 'Server Solutions', text: "Power your business operations with our server solutions. Whether you need on-premises or cloud-based servers, we design and deploy scalable, secure, and high-performance server infrastructures tailored to your business needs."},
        {heading: 'Audiovisual', text: "Create immersive and engaging environments with our audiovisual solutions. From conference room setups to digital signage and multimedia presentations, we offer end-to-end AV solutions that enhance communication and collaboration."},
        {heading: 'Access Control', text: "Protect your premises and assets with our access control systems. We design and install advanced access control solutions that regulate entry and monitor activity, ensuring maximum security and control."},
        {heading: 'Roll-Out Services', text: "Seamlessly deploy technology across multiple locations with our roll-out services. Our project management experts coordinate logistics, installation, and testing to ensure smooth roll-outs with minimal disruption to your business operations."},
        {heading: 'Fiber Installation', text: "Leverage the speed and reliability of fiber optics with our installation services. We design and deploy fiber optic networks that deliver high-bandwidth connectivity for data-intensive applications and future scalability."}
    ];

    return (
        <div className='services--b'>
            <div className='services--b--container'>
                <div className='services--left'>
                    <img src='https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_42.png?v=1708372242' />
                </div>
                <div className='services--right'>
                    <div className='services--right--container'>
                        <div style={{maxWidth: '600px'}}>
                            <p>Our team of experienced professionals is ​committed to delivering high-quality solutions ​that enhance efficiency, security, and ​productivity. Our well-trained travel teams will provide these services nationwide:</p>
                        </div>
                        <ul>
                            {
                                serviceBullets.map((i) => {
                                    return (
                                        <ServiceBullets heading={i.heading} text={i.text} />
                                    );
                                })
                            }
                        </ul>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default ServicesSectionB;