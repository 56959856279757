import { useState } from 'react';
import './ServicesSection.css';
import ServiceCards from '../ServiceCards/ServiceCards';

function ServicesSection( ) {
    const ourServices = [
        {serviceName: 'mobile devices', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_36.png?v=1708359161', text: "Expert support for setup, configuration, and troubleshooting of smartphones and tablets, ensuring seamless connectivity and productivity on the go for individuals and businesses alike."}, 
        {serviceName: 'networking', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_37.png?v=1708360998', text: "Tailored solutions for building, optimizing, and maintaining network infrastructure, delivering reliable connectivity and scalability to support your business operations effectively."},
        {serviceName: 'Hardware', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_38.png?v=1708360999', text: "Comprehensive support for servers, workstations, printers, and peripherals, ensuring optimal performance and longevity of your hardware investments with certified expertise and industry-leading solutions."},
        {serviceName: 'hardware and network troubleshooting', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_39.png?v=1708360998', text: "Diagnoses and resolves hardware and network issues to minimize downtime and optimize productivity, with proactive monitoring and maintenance to keep your infrastructure running smoothly and securely."},
        {serviceName: 'Security', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_40.png?v=1708360998', text: "Protection against cyber threats, providing risk assessments, security audits, and implementation of robust measures to safeguard your digital assets and comply with regulatory standards, tailored to your specific needs and industry requirements."},
        {serviceName: 'Software ​Troubleshooting', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_41.png?v=1708360998', text: "Expert assistance in diagnosing and resolving software-related issues, ensuring optimal performance and reliability of your systems and applications, minimizing disruptions and maximizing productivity."},
    ]

    return (
        <div className='services'>
            <div className='services--container'>
                <div className='services--message'>
                    <h3>What We Offer</h3>
                    <div className='services--underline' ></div>
                    <p>At AI ProTechs, we pride ourselves on providing a comprehensive range of IT services to meet the ​diverse needs of businesses across various industries. With our expertise, dedication, and cutting-​edge technology solutions, we empower our clients to thrive in today's digital landscape.</p>
                    <p>Here's a glimpse of what we offer:</p>
                </div>
                <div className='services--grid'>
                    {ourServices.map((i) => {
                        return (
                            <ServiceCards img={i.img} serviceName={i.serviceName} text={i.text} />
                        );
                    })}
                </div>

            </div>
        </div>
    );
}

export default ServicesSection;