const branding = {
    businessName: 'AI ProTechs',
    logoSM: '',
    logoMD: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/unnamed.png?v=1708113018',
    phoneNumber: 7282018429,
    phoneString: '+ 1 (728) 201-8429',
    businessEmail: 'support@aiprotechs.co',
    facebook: '',
    instagram: '',
    twitter: ''
  }

  const navMenu = [
    {to: '/', item: 'Home'},
    {to: '/careers', item:' Careers'},
    {to: '/contact', item: 'Contact Us'},
    // {to: '/login', item: 'Login'},
    // {to: '/signup', item: 'Get Started'},
];

const clientMenu = [
  {item: 'Request Service', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Shield_Vector_Illustration_Design_7.png?v=1708713017', url: 'request-service'},
  {item: 'Support', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Shield_Vector_Illustration_Design_9.png?v=1708713017', url: 'support'},
  {item: 'Billing', img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Shield_Vector_Illustration_Design_8.png?v=1708713017', url: 'billing'},
];

const usStates = [
  
'Alabama (AL)', 'Alaska (AK)', 'American Samoa (AS)', 'Arizona (AZ)', 'Arkansas (AR)', 'California (CA)', 'Colorado (CO)', 'Connecticut (CT)', 'Delaware (DE)', 'District of Columbia (DC)', 'Florida (FL)', 'Georgia (GA)', 'Guam (GU)', 'Hawaii (HI)', 'Idaho (ID)', 'Illinois (IL)', 'Indiana (IN)', 'Iowa (IA)', 'Kansas (KS)', 'Kentucky (KY)', 'Louisiana (LA)', 'Maine (ME)', 'Maryland (MD)', 'Massachusetts (MA)', 'Michigan (MI)', 'Minnesota (MN)', 'Mississippi (MS)', 'Missouri (MO)', 'Montana (MT)', 'Nebraska (NE)', 'Nevada (NV)', 'New Hampshire (NH)', 'New Jersey (NJ)', 'New Mexico (NM)', 'New York (NY)', 'North Carolina (NC)', 'North Dakota (ND)', 'Northern Mariana Islands (MP)', 'Ohio (OH)', 'Oklahoma (OK)', 'Oregon (OR)', 'Pennsylvania (PA)', 'Puerto Rico (PR)', 'Rhode Island (RI)', 'South Carolina (SC)', 'South Dakota (SD)', 'Tennessee (TN)', 'Texas (TX)', 'Utah (UT)', 'Vermont (VT)', 'Virgin Islands (VI)', 'Virginia (VA)', 'Washington (WA)', 'West Virginia (WV)', 'Wisconsin (WI)', 'Wyoming (WY)', 'Other'
];

const jobOpenings = [
  {
    
  }
]

export { branding,navMenu, clientMenu, usStates }