import './LeadFormBottom.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { doc, setDoc } from "firebase/firestore"; 
import { db } from '../../firebase/firebase';

function LeadFormBottom () {
    const navigate = useNavigate();
    
    const [email, setEmail] = useState('');

    const changeHandler = (e) => {
        setEmail(e.target.value);
    }

    const submitHandler = async (e) => {
        const capturedLocation = window.location.href;
        const capturedTime = new Date();

        e.preventDefault();
        await setDoc(doc(db, "leadCapture", email), {
            capturedLocation: capturedLocation,
            capturedTime: capturedTime
        });
        navigate('/contact?email=' + email);
    }

    return (
        <div className='lead-form-bottom'>
            <div className='lead-form-bottom--container'>
                <form onSubmit={submitHandler} className='bottom-form'>
                    <h3>Let's work together</h3>
                    <p>Choose AI ProTechs as your trusted IT partner. Let us ​empower your business with innovative technology ​solutions and unparalleled service excellence.</p>
                    <input required type='email' placeholder='Email' onChange={changeHandler} />
                    <button>Get Started</button>
                </form>
                
                
            </div>
        </div>
    );
}

export default LeadFormBottom;