import './FooterContact.css';
import { branding } from '../../data/data';

function FooterContact () {
    return (
            <div className='footer-contact'>
                
                <div className='bottom--contacts' >
                        <div className='bottom--contact'><h4>Phone</h4><a href={'tel:' + branding.phoneNumber}>{branding.phoneString}</a></div>
                        <div className='bottom--contact'><h4>Email</h4><a href={'mailto:' + branding.businessEmail}>{branding.businessEmail}</a></div>
                        <div className='bottom--contact'><h4>Social Media</h4><a>Coming soon</a></div>
                    </div>
            
            </div>
    );
}

export default FooterContact;